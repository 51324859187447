import React from 'react';
import PropTypes from 'prop-types';

import { FlatButton } from '../../../../components/button';

import HotelForm from '../hotel/editItem';
import NewHotelForm from '../hotel/newItem';
import PartHotelForm from '../hotel/partEditItem';
import { NewGroupHotelForm } from '../groupHotel/newItem';

import AirlineForm from '../air';
import NewAirlineForm from '../air/newItem';

import { TransferForm } from '../transfer';
import { PartTransferForm } from '../transfer/components/PartEditItem';

import { AddServiceFormAir } from '../air/components/AddServiceFormAir';
import { AddServiceTitle } from '../air/components/AddServiceTitle';

import { Insurance } from '../insurance';
import { InsuranceEdit } from '../insurance/editItem';

import NewCustomForm from '../custom';
import NewBusForm from '../bus';
import TrainView from '../train/view';
import NewTrainForm from '../train/newItem';
import EditTrainForm from '../train/editItem';
import EditPartTrainForm from '../train/partEditItem';
import VipZalForm from '../vipZal';
import EventsForm from '../events';

import { PartVipHallForm } from '../vipZal/components/PartEdit';

import AddTaxiTrip from '../taxi';

import { AeroExpressForm } from '../aeroexpress/newItem';

import { SERVICETYPE } from '../../../../bi/constants/serviceType';

import styles from '../../styles/trip.module.scss';

const FIELDLABEL = {
  ADD: {
    TITLE: 'Создать заказ',
    NAME: 'Наименование нового заказа',
  },
  EDIT: {
    TITLE: 'Изменить заказ',
    NAME: 'Наименование изменения',
  },
  ADD_TRIP_TAXI: {
    TITLE: 'Добавить поездку',
    NAME: 'Наименование новой поездки',
  },
};

const LABELS = {
  CANCEL: 'Отменить',
};

export function renderEdit(props) {
  const {
    tripService,
    airService,
    travelPolicyService,
    item,
    projects,
    onConfirm,
    onCancel,
    onAddTrip,
    changeRate,
    accountId,
    analytics,
    tripAnalyticsValueIds,
    setTripAnalytics,
    guidService,
    specialBillAccount,
    notificationsService,
    mandatoryProject,
    loading,
    companies,
    TripId,
  } = props;

  if (!item) return null;

  const label = item.ServiceType !== SERVICETYPE.TAXI
    ? FIELDLABEL.EDIT
    : FIELDLABEL.ADD_TRIP_TAXI;

  let form = null;

  switch (item.ServiceType) {
    case SERVICETYPE.HOTEL:
      form = (
        <HotelForm
          hotel={ item }
          onConfirm={ onConfirm }
          tripService={ tripService }
          travelPolicyService={ travelPolicyService }
          projects={ projects }
          accountId={ accountId }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          analytics={ analytics }
          setTripAnalytics={ setTripAnalytics }
          notificationsService={ notificationsService }
          mandatoryProject={ mandatoryProject }
        />
      );

      break;

    case SERVICETYPE.AIR:
      form = (
        <AirlineForm
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          analytics={ analytics }
          airline={ item }
          onConfirm={ onConfirm }
          tripService={ tripService }
          airService={ airService }
          travelPolicyService={ travelPolicyService }
          projects={ projects }
          changeRate={ changeRate }
          accountId={ accountId }
          setTripAnalytics={ setTripAnalytics }
          specialBillAccount={ specialBillAccount }
          mandatoryProject={ mandatoryProject }
        />
      );

      break;

    case SERVICETYPE.TRANSFER:
      form = (
        <TransferForm
          isEditing
          transfer={ item }
          onConfirm={ onConfirm }
          tripService={ tripService }
          projects={ projects }
          mandatoryProject={ mandatoryProject }
          accountId={ accountId }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          analytics={ analytics }
          setTripAnalytics={ setTripAnalytics }
          airService={ airService }
          guidService={ guidService }
        />
      );

      break;

    case SERVICETYPE.TRAIN:
      form = (
        <EditTrainForm
          train={ item }
          onConfirm={ onConfirm }
          tripService={ tripService }
          projects={ projects }
          travelPolicyService={ travelPolicyService }
          accountId={ accountId }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          analytics={ analytics }
          setTripAnalytics={ setTripAnalytics }
          mandatoryProject={ mandatoryProject }
        />
      );

      break;

    case SERVICETYPE.CUSTOM:
      form = (
        <NewCustomForm
          isEditing
          custom={ item }
          onConfirm={ onConfirm }
          tripService={ tripService }
          projects={ projects }
          accountId={ accountId }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          analytics={ analytics }
          setTripAnalytics={ setTripAnalytics }
          guidService={ guidService }
          mandatoryProject={ mandatoryProject }
        />
      );

      break;

    case SERVICETYPE.EVENT:
      form = (
        <EventsForm
          isEditing
          event={ item }
          onConfirm={ onConfirm }
          tripService={ tripService }
          projects={ projects }
          accountId={ accountId }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          analytics={ analytics }
          setTripAnalytics={ setTripAnalytics }
          guidService={ guidService }
          mandatoryProject={ mandatoryProject }
        />
      );

      break;

    case SERVICETYPE.VIP_HALL:
      form = (
        <VipZalForm
          isEditing
          vipHall={ item }
          onConfirm={ onConfirm }
          tripService={ tripService }
          projects={ projects }
          accountId={ accountId }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          analytics={ analytics }
          autocomplete={ airService.autocomplete }
          setTripAnalytics={ setTripAnalytics }
          guidService={ guidService }
        />
      );

      break;

    case SERVICETYPE.TAXI:
      form = (
        <AddTaxiTrip
          onConfirm={ onAddTrip }
          taxi={ item }
          taxiValidation={ tripService.taxiTripFieldValidation }
          loading={ loading }
        />
      );

      break;

    case SERVICETYPE.INSURANCE: {
      form = (
        <InsuranceEdit
          item={ item }
          companies={ companies }
          tripService={ tripService }
          guidService={ guidService }
          onConfirm={ onConfirm }
          tripId={ TripId }
        />
      );

      break;
    }

    case SERVICETYPE.BUS: {
      form = (
        <NewBusForm
          isEditing
          bus={ item }
          onConfirm={ onConfirm }
          airService={ airService }
          tripService={ tripService }
          projects={ projects }
          accountId={ accountId }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          analytics={ analytics }
          setTripAnalytics={ setTripAnalytics }
          guidService={ guidService }
          mandatoryProject={ mandatoryProject }
          changeRate={ changeRate }
        />
      );

      break;
    }
  }

  return (
    <div className={ styles.panel }>
      <div className={ styles.title }>
        <div className={ styles.name }>
          { label.TITLE }
        </div>
        <div className={ styles.action }>
          <FlatButton
            onClick={ onCancel }
            label={ LABELS.CANCEL }
          />
        </div>
      </div>
      <div className={ styles.form }>
        <div className={ styles.row }>
          { form }
        </div>
      </div>
    </div>
  );
}

export function renderNew(props) {
  const {
    tripService,
    airService,
    aeroexpressService,
    travelPolicyService,
    item,
    accountId,
    companies,
    projectsForNewTrip,
    mandatoryProject,
    onConfirm,
    onCancel,
    analytics,
    tripAnalyticsValueIds,
    setTripAnalytics,
    addTrip,
    guidService,
    specialBillAccount,
    noOrder,
    changeRate,
    TripId,
    isItemCart,
    labelButton,
  } = props;

  if (!item) return null;

  const label = FIELDLABEL.ADD;

  let form = null;

  switch (item.ServiceType) {
    case SERVICETYPE.HOTEL: {
      form = (
        <NewHotelForm
          hotel={ item }
          companies={ companies }
          onConfirm={ onConfirm }
          tripService={ tripService }
          travelPolicyService={ travelPolicyService }
          projects={ projectsForNewTrip }
          mandatoryProject={ mandatoryProject }
          accountId={ accountId }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          analytics={ analytics }
          setTripAnalytics={ setTripAnalytics }
          addTrip={ addTrip }
          guidService={ guidService }
          isItemCart={ isItemCart }
          labelButton={ labelButton }
        />
      );

      break;
    }

    case SERVICETYPE.GROUP_HOTEL: {
      form = (
        <NewGroupHotelForm
          hotels={ item }
          companies={ companies }
          onConfirm={ onConfirm }
          tripService={ tripService }
          travelPolicyService={ travelPolicyService }
          accountId={ accountId }
          addTrip={ addTrip }
          guidService={ guidService }
          isItemCart={ isItemCart }
          labelButton={ labelButton }
        />
      );

      break;
    }

    case SERVICETYPE.TRAIN: {
      form = (
        <NewTrainForm
          train={ item }
          companies={ companies }
          tripService={ tripService }
          travelPolicyService={ travelPolicyService }
          onConfirm={ onConfirm }
          projects={ projectsForNewTrip }
          mandatoryProject={ mandatoryProject }
          accountId={ accountId }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          analytics={ analytics }
          setTripAnalytics={ setTripAnalytics }
          addTrip={ addTrip }
          guidService={ guidService }
          noOrder={ noOrder }
        />
      );

      break;
    }

    case SERVICETYPE.AIR: {
      form = (
        <NewAirlineForm
          airline={ item }
          companies={ companies }
          onConfirm={ onConfirm }
          tripService={ tripService }
          airService={ airService }
          travelPolicyService={ travelPolicyService }
          projects={ projectsForNewTrip }
          mandatoryProject={ mandatoryProject }
          accountId={ accountId }
          analytics={ analytics }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          setTripAnalytics={ setTripAnalytics }
          addTrip={ addTrip }
          guidService={ guidService }
          specialBillAccount={ specialBillAccount }
          changeRate={ changeRate }
        />
      );

      break;
    }

    case SERVICETYPE.TRANSFER: {
      form = (
        <TransferForm
          transfer={ item }
          companies={ companies }
          onConfirm={ onConfirm }
          tripService={ tripService }
          projects={ projectsForNewTrip }
          mandatoryProject={ mandatoryProject }
          accountId={ accountId }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          analytics={ analytics }
          setTripAnalytics={ setTripAnalytics }
          airService={ airService }
          addTrip={ addTrip }
          guidService={ guidService }
        />
      );

      break;
    }

    case SERVICETYPE.CUSTOM: {
      form = (
        <NewCustomForm
          custom={ item }
          companies={ companies }
          onConfirm={ onConfirm }
          tripService={ tripService }
          projects={ projectsForNewTrip }
          accountId={ accountId }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          analytics={ analytics }
          setTripAnalytics={ setTripAnalytics }
          addTrip={ addTrip }
          guidService={ guidService }
          mandatoryProject={ mandatoryProject }
        />
      );

      break;
    }

    case SERVICETYPE.EVENT: {
      form = (
        <EventsForm
          isNew
          event={ item }
          companies={ companies }
          onConfirm={ onConfirm }
          tripService={ tripService }
          projects={ projectsForNewTrip }
          accountId={ accountId }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          analytics={ analytics }
          setTripAnalytics={ setTripAnalytics }
          addTrip={ addTrip }
          guidService={ guidService }
          mandatoryProject={ mandatoryProject }
        />
      );

      break;
    }

    case SERVICETYPE.VIP_HALL: {
      form = (
        <VipZalForm
          vipHall={ item }
          companies={ companies }
          onConfirm={ onConfirm }
          tripService={ tripService }
          projects={ projectsForNewTrip }
          mandatoryProject={ mandatoryProject }
          accountId={ accountId }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          analytics={ analytics }
          autocomplete={ airService.autocomplete }
          setTripAnalytics={ setTripAnalytics }
          addTrip={ addTrip }
          guidService={ guidService }
        />
      );

      break;
    }

    case SERVICETYPE.AEROEXPRESS: {
      form = (
        <AeroExpressForm
          aeroexpress={ item }
          analytics={ analytics }
          companies={ companies }
          tripService={ tripService }
          mandatoryProject={ mandatoryProject }
          aeroexpressService={ aeroexpressService }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          setTripAnalytics={ setTripAnalytics }
          projects={ projectsForNewTrip }
          guidService={ guidService }
          addTrip={ addTrip }
          onConfirm={ onConfirm }
        />
      );

      break;
    }

    case SERVICETYPE.AIR_ADDITIONAL_SERVICE: {
      form = (
        <AddServiceFormAir
          item={ item }
          onConfirm={ onConfirm }
          airService={ airService }
          tripService={ tripService }
          guidService={ guidService }
          addTrip={ addTrip }
        />
      );

      break;
    }

    case SERVICETYPE.INSURANCE: {
      form = (
        <Insurance
          item={ item }
          companies={ companies }
          tripService={ tripService }
          guidService={ guidService }
          onConfirm={ onConfirm }
          addTrip={ addTrip }
          tripId={ TripId }
        />
      );

      break;
    }

    case SERVICETYPE.BUS: {
      form = (
        <NewBusForm
          bus={ item }
          accountId={ accountId }
          addTrip={ addTrip }
          airService={ airService }
          analytics={ analytics }
          changeRate={ changeRate }
          companies={ companies }
          guidService={ guidService }
          mandatoryProject={ mandatoryProject }
          onConfirm={ onConfirm }
          projects={ projectsForNewTrip }
          setTripAnalytics={ setTripAnalytics }
          travelPolicyService={ travelPolicyService }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          tripService={ tripService }
        />
      );

      break;
    }
  }

  return (
    <div>
      <AddServiceTitle item={ item } TripId={ TripId } />
      <div className={ styles.panel }>
        <div className={ styles.title }>
          <div className={ styles.name }>
            { label.TITLE }
          </div>
          <div className={ styles.action }>
            <FlatButton
              onClick={ onCancel }
              label={ LABELS.CANCEL }
            />
          </div>
        </div>
        <div className={ styles.form }>
          <div className={ styles.row }>
            { form }
          </div>
        </div>
      </div>
    </div>
  );
}

export function renderView(item, tripService, onClose) { // пока оставить
  if (!item) return null;

  let form = null;

  switch (item.ServiceType) {
    case SERVICETYPE.TRAIN:
      form = (
        <TrainView
          train={ item }
          tripService={ tripService }
        />
      );
      break;
  }

  return (
    <div className={ styles.panel }>
      <div className={ styles.title }>
        <div className={ styles.name }>Просмотр</div>
        <div className={ styles.action }>
          <FlatButton
            onClick={ onClose }
            label='Закрыть'
          />
        </div>
      </div>
      <div className={ styles.form }>
        <div className={ styles.row }>
          { form }
        </div>
      </div>
    </div>
  );
}

export function renderCopy(props) {
  const {
    tripService,
    airService,
    aeroexpressService,
    travelPolicyService,
    item,
    companies,
    projectsForNewTrip,
    onConfirm,
    onCancel,
    accountId,
    analytics,
    tripAnalyticsValueIds,
    setTripAnalytics,
    addTrip,
    guidService,
    mandatoryProject,
    specialBillAccount,
    changeRate,
    TripId,
  } = props;

  if (!item) return null;

  const label = FIELDLABEL.ADD;

  let form = null;
  switch (item.ServiceType) {
    case SERVICETYPE.CUSTOM: {
      form = (
        <NewCustomForm
          custom={ item }
          companies={ companies }
          onConfirm={ onConfirm }
          tripService={ tripService }
          projects={ projectsForNewTrip }
          accountId={ accountId }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          analytics={ analytics }
          setTripAnalytics={ setTripAnalytics }
          addTrip={ addTrip }
          guidService={ guidService }
          mandatoryProject={ mandatoryProject }
        />
      );

      break;
    }

    case SERVICETYPE.EVENT: {
      form = (
        <EventsForm
          event={ item }
          companies={ companies }
          onConfirm={ onConfirm }
          tripService={ tripService }
          projects={ projectsForNewTrip }
          accountId={ accountId }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          analytics={ analytics }
          setTripAnalytics={ setTripAnalytics }
          addTrip={ addTrip }
          guidService={ guidService }
          mandatoryProject={ mandatoryProject }
        />
      );

      break;
    }

    case SERVICETYPE.AIR: {
      form = (
        <NewAirlineForm
          airline={ item }
          companies={ companies }
          onConfirm={ onConfirm }
          tripService={ tripService }
          airService={ airService }
          travelPolicyService={ travelPolicyService }
          projects={ projectsForNewTrip }
          mandatoryProject={ mandatoryProject }
          accountId={ accountId }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          analytics={ analytics }
          setTripAnalytics={ setTripAnalytics }
          addTrip={ addTrip }
          guidService={ guidService }
          specialBillAccount={ specialBillAccount }
          changeRate={ changeRate }
        />
      );

      break;
    }

    case SERVICETYPE.TRANSFER: {
      form = (
        <TransferForm
          transfer={ item }
          companies={ companies }
          onConfirm={ onConfirm }
          tripService={ tripService }
          projects={ projectsForNewTrip }
          accountId={ accountId }
          mandatoryProject={ mandatoryProject }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          analytics={ analytics }
          setTripAnalytics={ setTripAnalytics }
          airService={ airService }
          addTrip={ addTrip }
          guidService={ guidService }
          isCopy
        />
      );

      break;
    }

    case SERVICETYPE.HOTEL: {
      form = (
        <NewHotelForm
          hotel={ item }
          companies={ companies }
          onConfirm={ onConfirm }
          tripService={ tripService }
          travelPolicyService={ travelPolicyService }
          projects={ projectsForNewTrip }
          mandatoryProject={ mandatoryProject }
          accountId={ accountId }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          analytics={ analytics }
          setTripAnalytics={ setTripAnalytics }
          addTrip={ addTrip }
          guidService={ guidService }
          isCopy
        />
      );

      break;
    }

    case SERVICETYPE.TRAIN: {
      form = (
        <NewTrainForm
          train={ item }
          companies={ companies }
          tripService={ tripService }
          travelPolicyService={ travelPolicyService }
          onConfirm={ onConfirm }
          projects={ projectsForNewTrip }
          mandatoryProject={ mandatoryProject }
          accountId={ accountId }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          analytics={ analytics }
          setTripAnalytics={ setTripAnalytics }
          addTrip={ addTrip }
          guidService={ guidService }
          isCopy
        />
      );

      break;
    }

    case SERVICETYPE.VIP_HALL: {
      form = (
        <VipZalForm
          vipHall={ item }
          companies={ companies }
          onConfirm={ onConfirm }
          tripService={ tripService }
          projects={ projectsForNewTrip }
          mandatoryProject={ mandatoryProject }
          accountId={ accountId }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          analytics={ analytics }
          autocomplete={ airService.autocomplete }
          setTripAnalytics={ setTripAnalytics }
          addTrip={ addTrip }
          guidService={ guidService }
          isCopy
        />
      );

      break;
    }

    case SERVICETYPE.AEROEXPRESS: {
      form = (
        <AeroExpressForm
          aeroexpress={ item }
          analytics={ analytics }
          companies={ companies }
          tripService={ tripService }
          mandatoryProject={ mandatoryProject }
          aeroexpressService={ aeroexpressService }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          setTripAnalytics={ setTripAnalytics }
          projects={ projectsForNewTrip }
          guidService={ guidService }
          addTrip={ addTrip }
          onConfirm={ onConfirm }
          isCopy
        />
      );

      break;
    }

    case SERVICETYPE.AIR_ADDITIONAL_SERVICE: {
      form = (
        <AddServiceFormAir
          item={ item }
          onConfirm={ onConfirm }
          airService={ airService }
          tripService={ tripService }
          guidService={ guidService }
          addTrip={ addTrip }
          isCopy
        />
      );

      break;
    }

    case SERVICETYPE.INSURANCE: {
      form = (
        <Insurance
          item={ item }
          companies={ companies }
          tripService={ tripService }
          guidService={ guidService }
          onConfirm={ onConfirm }
          addTrip={ addTrip }
          tripId={ TripId }
        />
      );

      break;
    }

    case SERVICETYPE.BUS: {
      form = (
        <NewBusForm
          bus={ item }
          analytics={ analytics }
          companies={ companies }
          airService={ airService }
          tripService={ tripService }
          mandatoryProject={ mandatoryProject }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          setTripAnalytics={ setTripAnalytics }
          projects={ projectsForNewTrip }
          guidService={ guidService }
          addTrip={ addTrip }
          onConfirm={ onConfirm }
          changeRate={ changeRate }
          isCopy
        />
      );

      break;
    }
  }

  return (
    <div>
      <AddServiceTitle item={ item } TripId={ TripId } />
      <div className={ styles.panel }>
        <div className={ styles.title }>
          <div className={ styles.name }>
            { label.TITLE }
          </div>
          <div className={ styles.action }>
            <FlatButton
              onClick={ onCancel }
              label='Отменить'
            />
          </div>
        </div>
        <div className={ styles.form }>
          <div className={ styles.row }>
            { form }
          </div>
        </div>
      </div>
    </div>
  );
}

export const renderCacheItem = props => renderCopy(props);

export function renderPartEdit(
  item,
  specialBillAccount,
  onConfirm,
  onCancel,
  tripService,
  airService,
  companies,
  projects,
  travelPolicyService,
  accountId,
  analytics,
  tripAnalyticsValueIds,
  setTripAnalytics,
  changeRate,
  mandatoryProject,
  guidService,
) {
  if (!item) return null;

  const label = FIELDLABEL.EDIT;

  let form = null;
  switch (item.ServiceType) {
    case SERVICETYPE.HOTEL:
      form = (
        <PartHotelForm
          hotel={ item }
          onConfirm={ onConfirm }
          tripService={ tripService }
          companies={ companies }
          projects={ projects }
          accountId={ accountId }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          analytics={ analytics }
          setTripAnalytics={ setTripAnalytics }
          mandatoryProject={ mandatoryProject }
        />
      );

      break;

    case SERVICETYPE.AIR:
      form = (
        <AirlineForm
          airline={ item }
          onConfirm={ onConfirm }
          tripService={ tripService }
          airService={ airService }
          companies={ companies }
          projects={ projects }
          isAwaitingTicketing
          travelPolicyService={ travelPolicyService }
          accountId={ accountId }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          analytics={ analytics }
          setTripAnalytics={ setTripAnalytics }
          specialBillAccount={ specialBillAccount }
          changeRate={ changeRate }
          mandatoryProject={ mandatoryProject }
        />
      );

      break;

    case SERVICETYPE.TRANSFER:
      form = (
        <PartTransferForm
          transfer={ item }
          onConfirm={ onConfirm }
          companies={ companies }
          projects={ projects }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          analytics={ analytics }
          setTripAnalytics={ setTripAnalytics }
          tripService={ tripService }
        />
      );

      break;

    case SERVICETYPE.TRAIN:
      form = (
        <EditPartTrainForm
          train={ item }
          onConfirm={ onConfirm }
          tripService={ tripService }
          projects={ projects }
          travelPolicyService={ travelPolicyService }
          accountId={ accountId }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          analytics={ analytics }
          setTripAnalytics={ setTripAnalytics }
          mandatoryProject={ mandatoryProject }
          guidService={ guidService }
        />
      );

      break;

    case SERVICETYPE.VIP_HALL:
      form = (
        <PartVipHallForm
          vipHall={ item }
          onConfirm={ onConfirm }
          companies={ companies }
          projects={ projects }
          tripAnalyticsValueIds={ tripAnalyticsValueIds }
          analytics={ analytics }
          setTripAnalytics={ setTripAnalytics }
          tripService={ tripService }
        />
      );

      break;
  }

  return (
    <div className={ styles.panel }>
      <div className={ styles.title }>
        <div className={ styles.name }>
          { label.TITLE }
        </div>
        <div className={ styles.action }>
          <FlatButton
            onClick={ onCancel }
            label={ LABELS.CANCEL }
          />
        </div>
      </div>
      <div className={ styles.form }>
        <div className={ styles.row }>
          { form }
        </div>
      </div>
    </div>
  );
}

const commonPropTypes = {
  tripService: PropTypes.object.isRequired,
  airService: PropTypes.object.isRequired,
  travelPolicyService: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  employees: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  projectsForNewTrip: PropTypes.array.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  noOrder: PropTypes.bool.isRequired,
  changeRate: PropTypes.object.isRequired,
  mandatoryProject: PropTypes.object.isRequired,
};

renderEdit.propTypes = {
  ...commonPropTypes,
};

renderNew.propTypes = {
  ...commonPropTypes,
  companies: PropTypes.array.isRequired,
};

renderCopy.propTypes = {
  ...commonPropTypes,
  companies: PropTypes.array.isRequired,
};
