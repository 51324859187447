import React from 'react';
import PropTypes from 'prop-types';

import UploadForm from '../../../../../../components/UploadForm';

import { UPLOAD_FORM, REQUIRED_EXTENSIONS } from '../../../../../../bi/constants/trips';

import style from '../../../../styles/form.module.scss';

const LABELS = {
  ERROR_MSG: 'Произошла ошибка сервера, попробуйте позже',
};

const UploadVoucher = ({ uploadFile, eventFile, resetEventFile }) => {
  const { file, filed, uploaded } = eventFile;

  const errorMessage = filed ? <p className={ style['filed-message'] }>{ LABELS.ERROR_MSG }</p> : null;

  return (
    <div>
      <UploadForm
        isMaxSize
        extensions={ REQUIRED_EXTENSIONS }
        onFileAdded={ uploadFile }
        onFileRemoved={ resetEventFile }
        chooseFileLabel={ UPLOAD_FORM.CHOOSE_FILE }
        invalidExtension={ UPLOAD_FORM.INVALID_SIZE }
        description={ UPLOAD_FORM.DESCRIPTION }
        uploaded={ uploaded }
        file={ file }
      />
      { errorMessage }
    </div>
  );
};

UploadVoucher.propTypes = {
  uploadFile: PropTypes.func.isRequired,
  resetEventFile: PropTypes.func.isRequired,
  eventFile: PropTypes.object.isRequired,
};

export { UploadVoucher };
